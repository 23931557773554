#root,
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: rgba(155, 155, 155, 1);
    background: rgba(21, 21, 21, 1);
}

body {
    overflow: hidden;
    cursor: url('./assets/cursor.png') 39 39, auto;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 15s ease 0.3s forwards;
  animation-delay: 0s;
}

.middle {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-size: 3.8vh;
    letter-spacing: 0.5em;
    text-align: center;
    width: 100%;
    color: rgba(220, 220, 220, 0.9);
    padding: 1.5vh 1.5vh;
    margin: 1.5vh 0;
}

.middle > h1 {
    font-weight: 100;
    margin: -0.5vh 0;
}

.middle > h2 {
    line-height: 0;
    font-size: 3.5vh;
    font-weight: 100;
    letter-spacing: 0.25em;
    font-style: italic;
}